@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://rsms.me/inter/inter.css");

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  --transition-standard: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

html {
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
}

body {
  background-color: #ecfdf5;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
  transition: var(--transition-standard);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9ca3af;
}

/* Hide scrollbar but keep functionality */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/* Smooth scroll behavior */
.scroll-smooth {
  scroll-behavior: smooth;
}

.leaflet-tooltip {
  width: auto;
  height: auto;
  margin: 0px !important;
  padding: 2px !important;
  text-align: center !important;
  font-size: 11px !important;
  border-radius: 5px !important;
  border: none !important;
  background: #000 !important;
  color: #ffffff !important;
}

.leaflet-tooltip-top {
  background: #000 !important;
  color: #ffffff !important;
  width: auto !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.leaflet-bottom .leaflet-right {
  margin-bottom: 100px;
}

/* Position the div horizontally */
.search-input-control {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.leaflet-center {
  left: 50%;
  transform: translate(-50%, 0%);
}

.leaflet-verticalcenter {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  top: 50%; /* possible because the placeholder's parent is the map */
  transform: translateY(-50%); /* using the CSS3 Transform technique */
  padding-top: 10px;
}

.leaflet-verticalcenter .leaflet-control {
  margin-bottom: 10px;
}

#search-input {
  font-size: 12px;
  border-color: #242424;
}

.filters-button {
  background-color: #10b981;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  z-index: 1001;
}

/* Animation classes */
.animate-fadeIn {
  animation: fadeIn 0.5s ease-out;
}

.animate-slideDown {
  animation: slideDown 0.5s ease-out;
}

.animate-slideUp {
  animation: slideUp 0.5s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.typing-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 auto;
  width: fit-content;
}

.typing-container {
  display: inline-block;
  position: relative;
  width: fit-content;
}

.typing-text {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  visibility: hidden;
}

.typing-text::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  overflow: hidden;
  border-right: 2px solid #10b981;
  white-space: nowrap;
  opacity: 0;
  animation: fadeIn 0.1s ease-out forwards, typing 1.8s steps(40) forwards,
    blink 0.75s step-end infinite;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/* Custom gradient backgrounds */
.bg-gradient-hero {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(167, 243, 208, 0.2)
  );
}
